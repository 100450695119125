export default {
  appTitlePrefix: 'stg ',
  firebaseConfig: {
    apiKey: 'AIzaSyCjmM-G81Ur-FmiriBs_e9KXxefVNOM8vY',
    authDomain: 'stg-admin.coupon.gunosy.link',
    databaseURL: 'https://stg-media-admin.firebaseio.com',
    projectId: 'stg-media-admin',
    storageBucket: 'stg-media-admin.appspot.com',
    messagingSenderId: '989462746823',
    appId: '1:989462746823:web:0b7cb847fe17e30c',
  },
  googleOAuthClientID:
    '989462746823-r8ih0ilpgeio2norsiu2a4pr2126tdfg.apps.googleusercontent.com',
};
